﻿@import "_base-vars.less";

.defaultFont() {
    font-family: Roboto;
    font-weight: normal;
    font-style: normal;
}

.clearfix {
    &:before {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: " ";
        clear: both;
        height: 0;
        line-height: 0;
    }

    &:after {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: " ";
        clear: both;
        height: 0;
        line-height: 0;
    }
}

.dropcap() {
    &:first-letter {
        float: left;
        font-size: 3em;
        font-weight: bold;
        margin-right: 0.1em;
        line-height: 0.85em;
    }
}

.relativeHeight(@hRatio, @vRatio) {
    position: relative;
    height: auto;

    &:before {
        content: '';
        display: block;
        padding-top: (100% * @vRatio / @hRatio);
    }
}

.stroke(@width, @color) {
    text-shadow: -@width -@width 0 @color, -@width @width 0 @color, @width @width 0 @color, @width -@width 0 @color, -@width 0 0 @color, 0 -@width 0 @color, @width 0 0 @color, 0 @width 0 @color;
}

.spinner(@size, @strokeWidth) {
    background-color: transparent;
    border: @strokeWidth solid fade(@highlightColor, 90%);
    opacity: .9;
    border-top: @strokeWidth solid transparent;
    border-radius: @size;

    @shadowSpread: (@strokeWidth * 0.3);
    @shadowColor: #333;
    box-shadow: 0 0 @shadowSpread @shadowColor, 0 0 @shadowSpread @shadowColor inset;
    width: @size;
    height: @size;
    margin: 0 auto;
    -moz-animation: spin 0.5s infinite linear;
    -o-animation: spin 0.5s infinite linear;
    -webkit-animation: spin 0.5s infinite linear;
    animation: spin 0.5s infinite linear;
}

.bordergradient-bottomup(@from, @to) {
    -moz-border-image: -moz-linear-gradient(bottom, @from, @to) 1 100%;
    -o-border-image: -o-linear-gradient(bottom, @from, @to) 1 100%;
    -webkit-border-image: -webkit-linear-gradient(bottom, @from, @to) 1 100%;
    border-image: linear-gradient(to top, @from, @to) 1 100%;
}

.themed-button(@buttonColor) {
    border: 2px outset @buttonColor;
    background-color: @buttonColor;
    background: -webkit-linear-gradient(to bottom, mix(@buttonColor, @bodyInverseColor, 85%) 0, @buttonColor 50%, mix(@buttonColor, @bodyColor, 30%) 100%);
    background: -moz-linear-gradient(to bottom, mix(@buttonColor, @bodyInverseColor, 85%) 0, @buttonColor 50%, mix(@buttonColor, @bodyColor, 30%) 100%);
    background: -o-linear-gradient(to bottom, mix(@buttonColor, @bodyInverseColor, 85%) 0, @buttonColor 50%, mix(@buttonColor, @bodyColor, 30%) 100%);
    background: linear-gradient(to bottom, mix(@buttonColor, @bodyInverseColor, 85%) 0, @buttonColor 50%, mix(@buttonColor, @bodyColor, 30%) 100%);
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    background-size: 1px 200%;

    &:hover {
        background-position: 100%;
        background-color: mix(@buttonColor, @bodyColor, 75%);
    }

    &:active {
        background-color: mix(@buttonColor, @bodyColor, 50%);
        color: mix(@bodyColor, @bodyInverseColor, 25%);
        border-style: inset;
    }
}

.mobile-button() {
    .themed-button(@highlightColor);
    width: 2em;
    height: 2em;
    border-radius: 0.4em;
    margin: 0;
    padding: 0;
    cursor: pointer;
    top: 1.5em;
    z-index: 1000;

    &::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
    }
}

.title() {
    h1 {
        text-align: center;
        font-size: 3em;
        font-weight: bold;
        color: @color-primary;
        text-transform: uppercase;
        margin: 0.5em;
        padding: 0 0 0.25em 0;
        position: relative;

        &::after {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-image: url("../../images/title-separator.png");
            background-position: bottom center;
            background-repeat: no-repeat;
        }
    }

    & > p {
        font-size: 1.5em;
        font-weight: 300;
        text-align: center;
        margin: 0.5em 2em 2em 2em;
        color: #828b93;
    }
}

.jqueryTabReset() {
    border: 0;
    border-radius: 0;
    background: none;
    color: @textColor;
    margin: 0;
    padding: 0;
    .defaultFont();

    & > ul {
        border: 0;
        border-radius: 0;
        color: @textColor;
        .defaultFont();
        margin: 0;
        padding: 0;
        background: transparent;

        li {
            border: 0;
            border-radius: 0;
            background: none;
            color: @textColor;
            .defaultFont();
            float: none;
            list-style: none;
            position: relative;
            top: 0;
            margin: 0;
            padding: 0;
            white-space: nowrap;

            a {
                display: block;
                margin: 0;
                padding: 0;
                float: none;
            }
        }
    }
}

.tabs() {
    display: none;
    .jqueryTabReset();

    & > div > section {
        & > header {
            .title();
        }

        & > section {
            .clearfix();

            & > header {
                font-weight: bold;
                font-size: 1.2em;
            }

            & > aside {
                max-width: 33%;
                float: right;
                margin: 0 0 1em 1em;

                img {
                    max-width: 100%;
                }
            }

            li {
                list-style-position: inside;
            }

            &:nth-child(2n+1) {
                & > aside {
                    float: left;
                    margin: 0 1em 1em 0;
                }
            }
        }
    }

    & > ul {
        background-image: url("../../images/menu-background.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: table;
        table-layout: fixed;
        width: 100%;

        li {
            display: table-cell;
            width: 50%;
            box-shadow: inset -11px 0 8px -10px fade(#000, 20%), inset 0 -11px 8px -10px fade(#000, 20%);
            text-align: center;
            vertical-align: middle;

            a {
                display: block;
                color: @bodyColor;
                padding: 0.5em;
                font-weight: normal;
                text-align: center;
                vertical-align: middle;
            }

            &:hover::before {
                display: block;
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: fade(#fff,20%);
                pointer-events: none;
            }

            &.ui-tabs-active {
                a {
                    font-weight: bold;
                }

                background-color: fade(@highlightColor,30%);

                &:hover::before {
                    display: none;
                }
            }
        }

        @media @media-halfwidth {
            display: block;

            li {
                display: inline-block;
                float: left;
            }
        }
    }
}
