@import "_base-vars.less";
@import "_mixins.less";

body {
    & > header {
        position: relative;

        a, a:active, a:hover, a:visited {
            color: #fff;
            font-weight: normal;
            text-decoration: none;
        }

        nav {
            @media-navmobilewidth: ~"only screen and (max-width: 800px)";
            @media-navmobilesearchwidth: ~"only screen and (max-width: 1050px)";

            background-image: url("../../images/menu-background.png");
            background-size: 100% @navHeight;
            background-repeat: no-repeat;
            box-shadow: fade(@bodyInverseColor, 40%) 0 2px 10px 2px;

            @media @media-navmobilewidth {
                box-shadow: fade(@bodyInverseColor, 90%) 0 2px 10px 2px;
                width: 250px !important;
            }

            position: fixed;
            top: 0;
            right: 0;
            margin: 0;
            padding: 0;
            height: @navHeight;
            line-height: (@navHeight - 2px);
            z-index: 3;
            display: block;
            width: 100%;
            transition: all 100ms;
            text-align: right;

            #header-logo {
                display: block;
                width: 200px;
                height: (@navHeight - 10px);
                position: absolute;
                top: 5px;
                left: 5px;
                z-index: 3;
                cursor: pointer;
                background-image: url("../../images/logo.png");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: left center;
                font-size: 1px;
                color: transparent;

                @media @media-navmobilesearchwidth {
                    background-image: url("../../images/logo.png");
                }
            }

            ul {
                display: table;
                table-layout: auto;
                text-align: center;
                margin: 0 auto 0 auto;
                padding: 0;
                position: absolute;
                top: 0;
                right: 0;

                & > li {
                    display: table-cell;
                    min-width: 8em;
                    list-style-type: none;
                    margin: auto;
                    text-align: center;
                    vertical-align: middle;
                    white-space: nowrap;
                    text-transform: uppercase;
                    padding: 0 0.5em;

                    &:hover {
                        background-color: fade(white, 20%);
                    }

                    & > a {
                        display: inline-block;
                        text-align: center;
                        vertical-align: middle;

                        &::before {
                            display: inline-block;
                            content: '';
                            width: 15px;
                            height: 13px;
                            background-image: url("../../images/header/chevron.png");
                            background-position: left center;
                            background-repeat: no-repeat;
                        }
                    }

                    &:first-of-type {
                        text-align: right;
                    }

                    @media @media-navmobilewidth {
                        min-width: inherit;
                    }

                    &.header-search {
                        line-height: 24px;
                        padding-right: 3em;

                        input {
                            display: inline;
                            height: auto;
                            width: 100%;
                            padding: 0.1em 0.2em;
                            border-radius: 0.5em;
                        }

                        button[type=submit] {
                            border: 0;
                            background: none;
                            color: white;
                        }
                    }
                }
            }

            @media @media-navmobilesearchwidth {
                .header-home {
                    display: none;
                }

                ul.nosearch .header-home {
                    display: block;
                }
            }

            @media @media-navmobilewidth {
                .mobile-button();
                top: 1em;
                left: 1em;
                width: @navHeight;
                height: @navHeight;
                border-radius: 2em;

                #header-logo {
                    display: none;
                }

                .header-home {
                    display: table-cell;
                }

                & > ul {
                    display: none;
                    font-size: 0.95em;
                    border-radius: 1em;
                    box-shadow: fade(black,30%) 0.2em 0.2em 0.5em 0;

                    li {
                        text-align: left;
                    }
                }

                &::before {
                    background-repeat: no-repeat;
                    background-image: url("../../images/logo.png");
                    background-position: center center;
                    background-size: 90% 90%;
                }

                &:hover, &:focus, &:active {
                    & > ul {
                        display: block;
                        position: absolute;
                        top: 90%;
                        left: 10%;
                        z-index: 10;
                        border: 1px outset @borderColor;
                        min-width: 18.4em;

                        &.nosearch {
                            min-width: 10em;
                        }

                        & > li {
                            display: block;
                            float: none;
                            @liColor: @highlightColor;
                            background: linear-gradient(to top, mix(@color-primary,@liColor,40%) 0%, @liColor 75%, mix(@color-primary, @liColor,20%) 100%);
                            border: 1px solid @highlightColor;

                            a {
                                display: block;
                                text-align: left;
                            }

                            &:hover {
                                @liColor: mix(@highlightColor, @bodyInverseColor, 90%);
                                background: linear-gradient(to top, mix(@color-primary,@liColor,40%) 0%, @liColor 75%, mix(@color-primary, @liColor,20%) 100%);
                            }

                            &:first-child {
                                border-top-left-radius: 1em;
                                border-top-right-radius: 1em;
                            }

                            &:last-child {
                                border-bottom-left-radius: 1em;
                                border-bottom-right-radius: 1em;
                            }

                            &.header-search {
                                padding-top: (@navHeight - 2px - 24px)/2;
                                padding-bottom: (@navHeight - 2px - 24px)/2;

                                input {
                                    min-width: 15em;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.contact, &.solution, &.services, &.repfleet, &.error, &.activities {
        & > header {
            .relativeHeight(100, 25);

            &::before {
                background-image: url("../../images/header/home.jpg");
                background-size: cover;
                background-position: center center;
                z-index: 1;
            }
        }
    }
}
