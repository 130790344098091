﻿@import "_base-vars.less";
@import "_mixins.less";

@import "_home-activities.less";
@import "_home-services.less";

body.home > header {
    .relativeHeight(100, 45);

    & > .home-slideshow {
        ul {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: 0;
            padding: 0;

            & > li {
                list-style-type: none;
                margin: 0;
                padding: 0;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 0;
                background-size: cover;
                background-position: top center;
                display: none;

                &:first-of-type {
                    display: block;
                }
            }
        }

        #home-slideshow-previous, #home-slideshow-next {
            display: block;
            content: '';
            position: absolute;
            top: @navHeight;
            bottom: 0;
            width: 2em;
            z-index: 1;
            vertical-align: middle;
            text-align: center;
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
            cursor: pointer;

            &:hover {
                opacity: 0.7;
            }
        }

        #home-slideshow-previous {
            left: 0;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
        }

        #home-slideshow-next {
            right: 0;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
        }
    }
}

.button-login-home {
    background-color: transparent;
    border: 0;
    color: mix(@color-primary, @bodyColor, 20%);
    font-weight: bold;
    margin: 0.5em 0 0.5em;
    position: absolute;
    width: 280px;
    height: 53px;
    line-height: 53px;
    background-size: contain;
    text-align: center;
    vertical-align: middle;
    top: 45px;
    left: 25px;
    font-size: 25px;
    padding-left: 40px;
    z-index: 10;
    .img-footer-contact-send();
    color: white !important;

    &:hover {
        .img-footer-contact-send-hover();
        color: white !important;
    }
}

@media @media-halfwidth {
    .button-login-home {
        top: 150px;
        left: 50px;
    }
}
