﻿.img-footer-contact-send {
    background-repeat: no-repeat;
    background-size: 200%;
    background-image: url("../../../images/footer/contact-submit.png");
    background-position: 0 0;
}

.img-footer-contact-send-hover {
    background-repeat: no-repeat;
    background-size: 200%;
    background-image: url("../../../images/footer/contact-submit.png");
    background-position: 100% 0;
}
