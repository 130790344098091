﻿@import "_base-vars.less";
@import "_mixins.less";

body.solution {
    main {
        margin: 1em;

        & > header {
            .title();

            @media @media-halfwidth {
                h1 {
                    margin-top: 90px;
                }
            }

            a {
                background-color: transparent;
                border: 0;
                color: mix(@color-primary, @bodyColor, 20%);
                font-weight: bold;
                margin: 0.5em 0 0.5em;
                position: absolute;
                width: 280px;
                height: 53px;
                line-height: 53px;
                background-size: contain;
                text-align: center;
                vertical-align: middle;
                top: 100px;
                right: 50px;
                font-size: 25px;
                padding-left: 40px;
                .img-footer-contact-send();
                color: white !important;

                &:hover {
                    .img-footer-contact-send-hover();
                    color: white !important;
                }
            }
        }

        .description {
            .clearfix();
            text-align: left;
            vertical-align: top;
            margin-top: 1em;
            padding-top: 2em;
            padding-bottom: 2em;

            header {
                font-size: 1.3em;
                font-weight: normal;
            }

            aside {
                float: right;
                vertical-align: top;
                text-align: right;
                max-width: 33%;
                margin: 0 0 1em 1em;

                img {
                    max-width: 100%;
                }
            }

            & > header {
                vertical-align: middle;
                margin: 0 0 0.5em 0;

                & > img {
                    max-width: 100px;
                }

                .defaultFont();
                font-size: 2em;
                font-weight: 300;
                color: @highlightColor;
            }

            &:first-of-type {
                border: none;
                padding-top: 0;
            }

            & > p, & > ul {
                text-align: justify;
                color: #828b93;
                font-size: 1.1em;
            }
        }

        .details {
            .clearfix();
            text-align: center;

            & > header {
                .title();
            }

            ul {
                margin: 0 auto;
                padding: 0;

                li {
                    list-style-type: none;
                    margin: 1em;
                    padding: 0;
                    display: inline-block;
                    vertical-align: top;
                    text-align: center;
                    max-width: 30%;

                    img {
                        max-width: 15em;
                        max-height: 10vw;
                    }

                    &:nth-child(1), &:nth-child(2) {
                        max-width: 35%;

                        img {
                            max-width: 20em;
                            max-height: 15vw;
                        }
                    }

                    @media @media-halfwidth {
                        max-width: inherit;

                        img {
                            max-width: 40%;
                            max-height: none;
                        }

                        &:nth-child(1), &:nth-child(2) {
                            max-width: inherit;

                            img {
                                max-width: 40%;
                                max-height: none;
                            }
                        }
                    }

                    & > h2 {
                        .defaultFont();
                        font-size: 2em;
                        font-weight: 300;
                        color: @highlightColor;
                    }

                    & > p, & > ul {
                        text-align: justify;
                        color: #828b93;
                        font-size: 1.1em;
                    }
                }
            }
        }

        .search {
            padding-top: 50px;

            & > header {
                .title();
            }

            .result-display {
                display: inline-flex;

                @media @media-halfwidth {
                    display: inline-block;
                }

                width: 100%;

                .result-table {
                    width: 60%;

                    @media @media-halfwidth {
                        .clearfix();
                        width: auto;
                    }

                    .repairsolutioncount, .articlecount {
                        display: block;
                        font-size: 2em;

                        label {
                            color: rgb(37,38,169);
                            font-weight: bold;
                        }
                    }

                    .display-button {
                        width: 100%;
                        text-align: center;

                        a {
                            background-color: transparent;
                            border: 0;
                            color: mix(@color-primary, @bodyColor, 20%);
                            font-weight: bold;
                            margin: 0.5em 0;
                            position: absolute;
                            width: 280px;
                            height: 53px;
                            line-height: 53px;
                            background-size: contain;
                            text-align: center;
                            vertical-align: middle;
                            margin-top: 25px;
                            font-size: 25px;
                            padding-left: 40px;
                            .img-footer-contact-send();

                            @media @media-halfwidth {
                                .clearfix();
                                right: 50px;
                            }

                            &:hover {
                                .img-footer-contact-send-hover();
                            }
                        }
                    }
                }

                .result-contact {
                    width: 40%;

                    @media @media-halfwidth {
                        .clearfix();
                        width: auto;
                        margin-top: 100px;
                    }

                    & > header {
                        h1 {
                            text-align: center;
                            font-size: 3em;
                            font-weight: bold;
                            color: @color-primary;
                            text-transform: uppercase;
                            margin: 0.5em;
                            padding: 0 0 0.25em 0;
                            position: relative;
                        }

                        & > p {
                            font-size: 1.5em;
                            font-weight: 300;
                            text-align: center;
                            margin: 0.5em 2em 2em 2em;
                            color: #828b93;
                        }
                    }

                    .search-contact-form {
                        display: grid;
                        justify-content: center;

                        & > input {
                            padding: 0.3em;
                            margin: 0.2em;
                            border-radius: 0.5em;
                            width: 25em;
                        }

                        & > textarea {
                            padding: 0.3em;
                            margin: 0.2em;
                            border-radius: 0.5em;
                            min-height: 100px;
                            width: 25em;
                        }

                        & > button {
                            background: #00b6e3;
                            color: white;
                            border-radius: 0.5em;
                        }

                        & > .g-recaptcha {
                            border-radius: 0.5em;
                        }
                    }
                }
            }

            .no-result {
                width: 100%;
                justify-content: center;

                .no-data {
                    & > header {
                        & > p {
                            font-size: 1.5em;
                            font-weight: 300;
                            text-align: center;
                            margin: 0.5em 2em 2em 2em;
                            color: #828b93;
                        }
                    }

                    .search-contact-form {
                        display: grid;
                        justify-content: center;

                        & > input {
                            padding: 0.3em;
                            margin: 0.2em;
                            border-radius: 0.5em;
                            width: 25em;
                        }

                        & > textarea {
                            padding: 0.3em;
                            margin: 0.2em;
                            border-radius: 0.5em;
                            min-height: 100px;
                            width: 25em;
                        }

                        & > button {
                            background: #00b6e3;
                            color: white;
                            border-radius: 0.5em;
                        }

                        & > .g-recaptcha {
                            border-radius: 0.5em;
                        }
                    }
                }
            }

            .search-panel {
                text-align: center;

                .search-repfleet-bar {
                    min-height: 48px;
                    border-radius: 4px;
                    width: 70%;
                    vertical-align: top;
                }

                .search-repfleet-button {
                    display: inline-block;
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    font-size: 1.5em;
                    background: #00b6e3;
                    color: white;
                    border-radius: 5px;
                    -webkit-transition: .3s;
                    -o-transition: .3s;
                    transition: .3s;
                    margin: 0;
                    padding: 0;
                    border: unset;
                    vertical-align: top;
                }
            }

            table {
                width: 100%;
                margin-top: 0.5em;

                thead {
                    th {
                        text-align: left;
                        vertical-align: top;
                        padding: 0.4em;
                        background-color: rgb(0,101,189);
                        color: #fff;
                        font-weight: normal;
                        font-size: 0.9em;
                        font-variant: small-caps;
                        border: 1px solid #fff;
                        border-top-color: rgb(0,101,189);
                        border-bottom-color: rgb(0,101,189);

                        &:first-of-type {
                            border-left-color: rgb(0,101,189);
                        }

                        &:last-of-type {
                            border-right-color: rgb(0,101,189);
                        }
                    }
                }

                tbody {
                    td {
                        text-align: left;
                        padding: 0.4em 0.2em;
                        font-weight: normal;
                        vertical-align: top;
                        border: 1px solid rgb(222,222,222);

                        &.product {
                            .code {
                                display: block;
                                color: rgb(51,122,183);
                                font-weight: 700;
                                font-size: 1em;
                            }

                            .name {
                                display: block;
                                color: rgb(37,38,169);
                                font-weight: 300;
                                font-size: 0.75em;
                            }
                        }

                        &.supplierlocation {
                            font-weight: 700;
                            font-size: 1em;
                            color: transparent;
                            text-shadow: 0 0 8px rgb(51,122,183);
                            cursor: default;
                            user-select: none;
                            -webkit-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                        }

                        &.ratings {
                            color: rgb(37,38,169);
                            font-weight: 700;
                            font-size: 1em;
                        }

                        &.certificates {
                            color: rgb(51,122,183);
                            font-weight: 700;
                            font-size: 1em;
                        }
                    }
                }
            }
        }
    }
}
