﻿@import "_base-vars.less";
@import "_mixins.less";

.button-login-activities {
    background-color: transparent;
    border: 0;
    color: mix(@color-primary, @bodyColor, 20%);
    font-weight: bold;
    margin: 0.5em 0 0.5em;
    position: absolute;
    width: 280px;
    height: 53px;
    line-height: 53px;
    background-size: contain;
    text-align: center;
    vertical-align: middle;
    top: 100px;
    right: 50px;
    font-size: 25px;
    padding-left: 40px;
    color: white;
    .img-footer-contact-send();
    color: white !important;

    &:hover {
        .img-footer-contact-send-hover();
        color: white !important;
    }
}

#activities-tabs {
    .tabs();

    & > ul > li {
        width: 33%;
    }

    & > div > section > section {
        border-top: 2px solid #ddd;
        padding-top: 2em;
        padding-bottom: 2em;


        & > header {
            vertical-align: middle;
            margin: 0 0 0.5em 0;


            & > img {
                max-width: 100px;
            }

            .defaultFont();
            font-size: 2em;
            font-weight: 300;
            color: @highlightColor;
        }

        &:first-of-type {
            border: none;
            padding-top: 0;
        }

        & > p, & > ul {
            text-align: justify;
            color: #828b93;
            font-size: 1.1em;
        }
    }
}

@media @media-halfwidth {
    .button-login-activities {
        top: 150px;
    }

    #tab-airline,
    #tab-vendor,
    #tab-mro,
    #tab-broker,
    #tab-dismantlers,
    #tab-governmental {
        margin-top: 60px;
    }
}
