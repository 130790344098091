@import "_base-vars.less";
@import "_mixins.less";
@import "_fonts.less";

img {
    border: 0;
}

body {
    background-color: @bodyColor;
    color: @textColor;
}

body, section, header, h1, h2, h3, h4, h5, h6, p, div, li, a, form, input, select, textarea {
    .defaultFont();
}

main > ul.infos {
    background-color: fade(#ffd800, 20%);
    border-color: #ffd800;
}

main > ul.errors {
    background-color: fade(red, 20%);
    border-color: red;
}

main > ul.infos, main > ul.errors {
    margin: 0;
    padding: 0;
    border-width: 3px;
    border-style: double;
    border-radius: 0.5em;
    margin: 0.5em auto;
    padding: 0.5em;
    width: 50%;

    li {
        list-style: none;
    }
}

a {
    color: @linkColor;
    text-decoration: none;

    &:visited {
        color: @linkColor;
        text-decoration: none;
    }

    &:hover {
        color: mix(@linkColor, @bodyInverseColor, 75%);
        text-decoration: none;
    }

    &:active {
        color: mix(@linkColor, @bodyInverseColor, 50%);
        text-decoration: none;
    }
}

@import "_header.less";
@import "_footer.less";
@import "_home.less";
@import "_activities.less";
@import "_services.less";
@import "_solution.less";
