﻿@import "_base-vars.less";
@import "_mixins.less";

.home-services {
    .clearfix();
    text-align: center;
    margin-top: 4em;

    & > header {
        .title();
    }

    ul {
        margin: 1em auto 2em auto;
        padding: 0;

        li {
            display: inline-block;
            list-style-image: none;
            margin: 0 3em;
            padding: 0;
            font-size: 1.4em;
            font-weight: bold;
            text-align: center;

            a {
                display: block;
            }

            img {
                display: inline-block;
                max-width: 15vw;
            }
        }
    }
}
