﻿@import "_base-vars.less";
@import "_mixins.less";

.home-activities {
    background: url("../../images/activities/background.jpg") center top no-repeat fixed;
    background-size: cover;
    padding: 60px 0 40px 0;
    position: relative;
    z-index: 1;

    &::before {
        display: block;
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: fade(#fff, 90%);
        z-index: -1;
    }

    & > header {
        .title();
    }

    .home-activities-container {
        @sidebarWidth: 30vw;
        @carouselWidth: 70vw;
        @centerLogoScale: (1/2.5);
        @buttonScale: (@centerLogoScale/2);

        .clearfix();
        display: table;
        position: relative;
        margin: 4em 0 0 0;
        padding: 0;
        margin-right: @sidebarWidth;
        padding-right: 1em;
        width: 100%;

        .home-activities-carousel-container {
            display: table-cell;
            position: relative;
            margin: 0;
            padding: 0;
            text-align: center;

            .home-activities-carousel {
                position: relative;
                display: block;
                content: '';
                margin: auto;
                padding-right: 1em;
                width: @carouselWidth;
                height: @carouselWidth;
                max-width: 600px;
                max-height: 600px;
                background-image: url("../../images/home/activities-omnispares.png");
                background-repeat: no-repeat;
                background-position: center center;
                background-size: percentage(@centerLogoScale);

                li {
                    @centerTranslate: (0.5 - (@buttonScale/2));

                    list-style-type: none;
                    margin: 0;
                    padding: 0;
                    position: absolute;
                    width: percentage(@buttonScale);
                    height: percentage(@buttonScale);

                    & {
                        img {
                            transition: transform 100ms;
                            width: 100%;
                            height: 100%;
                        }

                        display: block;
                        content: '';
                        position: absolute;
                    }

                    label {
                        margin: 0 auto;
                        font-size: 1.4em;
                        font-family: Roboto;
                        font-weight: 400;
                        color: #aaa;
                        display: none;
                        position: absolute;
                        left: 50%;
                        right: 50%;
                        top: -1.4em;
                        transition: all 100ms;
                    }

                    &.active {
                        label {
                            transform: scale(1.2, 1.2);
                            top: -2.2em;
                            color: @highlightColor;
                        }

                        img {
                            transform: scale(1.2, 1.2);
                        }

                        @media @media-halfwidth {
                            label {
                                transform: none;
                                top: -1.4em;
                            }

                            img {
                                transform: none;
                            }
                        }
                    }

                    .buttonAngle(@angle) {
                        @radius: @centerLogoScale/2;
                        @distance: 0.05+@radius+(@buttonScale/2)+(@buttonScale/10);
                        @distanceX: cos(@angle)*@distance;
                        @distanceY: sin(@angle)*@distance;

                        left: percentage(@centerTranslate + @distanceX);
                        top: percentage(@centerTranslate + @distanceY);
                    }

                    &[data-type=airlines] {
                        .buttonAngle(180deg);
                    }

                    &[data-type=governmental] {
                        .buttonAngle(240deg);
                    }

                    &[data-type=vendors] {
                        .buttonAngle(300deg);
                    }

                    &[data-type=brokers] {
                        .buttonAngle(0deg);
                    }

                    &[data-type=mro] {
                        .buttonAngle(60deg);
                    }

                    &[data-type=dismantlers] {
                        .buttonAngle(120deg);
                    }
                }
            }
        }

        ul.home-activities-summaries {
            display: table-cell;
            position: relative;
            margin: 0;
            padding: 0;
            width: @sidebarWidth;

            @media @media-halfwidth {
                display: none;
            }

            &::before {
                display: block;
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                width: 20px;
                background-image: url("../../images/column-separator.png");
                background-position: left top;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }

            li {
                list-style-type: none;
                margin: 0;
                padding: 0;
                position: absolute;
                left: 25px;
                top: 0;
                bottom: 0;
                display: none;
                transition: display 100ms;

                &.active {
                    display: block;
                }

                & > header {
                    font-size: 2.5em;
                    font-weight: 300;
                    color: @highlightColor;
                }

                & > p {
                    padding: 1em 0.5em 1em 0;
                    font-size: 1.2em;
                    word-wrap: break-word;
                }

                & > a {
                    font-weight: bold;
                    white-space: nowrap;
                }
            }
        }
    }
}
