﻿@import "_base-vars.less";
@import "_mixins.less";
@import "sprites/footer-contact-submit.png.less";

body {
    & > footer {
        position: relative;
        background-image: url("../../images/footer/background.png");
        background-size: 100%;
        background-repeat: repeat-y;
        z-index: 2;
        padding: 1em 3em;
        color: @bodyColor;

        a, a:active, a:hover, a:visited {
            color: #fff;
            font-weight: normal;
            text-decoration: none;
        }

        .footer-repfleet, .footer-counters {
            margin: -1em -3em 1em -3em;
            text-align: center;
            vertical-align: middle;
            background-image: url("../../images/footer/top-background.jpg");
            background-size: cover;
            background-position: top center;
            box-shadow: fade(@bodyInverseColor, 50%) 0 2px 15px 2px;
        }

        .footer-repfleet {
            padding: 5em 0;

            img {
                max-width: 50%;
            }
        }

        .footer-counters {
            padding: 3em 1em;

            ul {
                table-layout: fixed;
                margin: auto;
                padding: 0;
                visibility: hidden;

                li {
                    display: inline-block;
                    list-style-type: none;
                    margin: 0;
                    padding: 0.5em 1em;
                    width: 25em;

                    .counter {
                        .stroke(1px, @textColor);
                        display: block;
                        font-size: 2em;

                        output {
                            display: inline;
                            color: @bodyColor;
                            font-size: 3em;
                        }
                    }

                    .label {
                        .stroke(1px, @textColor);
                        display: block;
                        font-size: 2em;
                        font-weight: bold;
                    }
                }
            }

            p {
                .stroke(2px, @color-primary);
                margin-top: 1em;
                font-size: 3.5em;
                font-weight: bold;
            }
        }

        .footer-navigation {
            width: 50%;
            float: left;
            margin-bottom: 2em;

            @media @media-halfwidth {
                .clearfix();
                width: auto;
                float: none;
            }

            strong {
                text-transform: uppercase;
            }

            .footer-logo {
                display: block;
                margin: 1em 0;

                img {
                    height: 3em;
                }
            }

            .footer-address {
                float: left;
                margin: 2em 0;
                padding-right: 2em;
            }

            .footer-quicklinks {
                float: left;
                margin: 2em 0;
                padding-left: 2em;
                text-transform: uppercase;

                ul {
                    margin: 0;
                    padding: 0;

                    li {
                        list-style-type: none;

                        a:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .footer-contact {
            width: 50%;
            float: right;
            margin-bottom: 2em;
            position: relative;

            @media @media-halfwidth {
                .clearfix();
                width: auto;
                float: none;
            }

            p a:hover {
                text-decoration: underline;
            }

            h1 {
                font-size: 2em;
                font-weight: bold;
            }

            form {
                .clearfix();
                text-align: center;

                input, textarea {
                    float: left;
                    padding: 0.5em;
                    font-size: 0.9em;
                    border-radius: 0.5em;
                    color: @textColor;
                }

                input[name='name'] {
                    width: 48%;
                    float: left;
                    margin: 0 2% 2% 0;
                }

                input[name='email'] {
                    width: 48%;
                    float: right;
                    margin: 0 0% 2% 2%;
                }

                textarea {
                    .clearfix();
                    width: 100%;
                    min-height: 10em;
                }

                .g-recaptcha {
                    float: left;
                }

                button[type='submit'] {
                    background-color: transparent;
                    border: 0;
                    float: right;
                    color: mix(@color-primary, @bodyColor, 20%);
                    font-weight: bold;
                    margin: 0.5em 0;
                    position: relative;
                    width: 280px;
                    height: 53px;
                    line-height: 53px;
                    background-size: contain;
                    text-align: center;
                    vertical-align: middle;
                    .img-footer-contact-send();

                    &:hover {
                        .img-footer-contact-send-hover();
                    }
                }

                @media only screen and (max-width: 1270px) {
                    .g-recaptcha {
                        float: none;
                        text-align: center;
                        margin: 1em auto;
                        display: inline-block;
                    }

                    button[type='submit'] {
                        float: none;
                        text-align: center;
                        margin: 1em auto;
                        display: inline-block;
                    }
                }
            }

            .footer-contact-social {
                ul {
                    display: inline-block;
                    margin: 0;
                    padding: 0;

                    li {
                        display: inline;
                        list-style-type: none;
                        padding: 0.2em;

                        img {
                            max-height: 2em;
                            border-radius: 50%;
                            transition: box-shadow 100ms;
                        }

                        &:first-of-type {
                            padding-left: 0;
                        }

                        &:hover img {
                            box-shadow: fade(@bodyColor, 80%) 0 0 15px 2px;
                            transition: box-shadow 200ms;
                        }
                    }
                }
            }
        }

        .footer-copyright {
            .clearfix();
            text-align: center;

            &, & a, & a:active, & a:hover, & a:visited {
                color: @bodyColor;
            }
        }
    }

    .oss-about {
        .clearfix();
        background: linear-gradient(to top, #ddd 0%, #eee 95%, #fff 100%);
        color: @highlightColor;
        padding: 2em 0;

        & > header {
            position: relative;
            text-align: center;
            font-family: Roboto;
            font-size: 4em;
            font-weight: bold;
            color: @highlightColor;
            margin: 0.3em;
            padding: 0;
            text-shadow: 1px 1px 2px mix(@color-primary-accent, @bodyInverseColor, 50%);
            vertical-align: middle;

            img {
                height: 1em;
                vertical-align: baseline;
            }
        }

        & > section {
            width: 40%;
            vertical-align: top;
            text-align: justify;
            margin: 1em 5%;

            header {
                font-family: Roboto;
                font-weight: 300;
                font-size: 2.7em;
            }

            p {
                font-family: Roboto;
                font-weight: normal;
                font-size: 1.2em;
            }
        }

        & > section.oss-about-who {
            float: left;
        }

        & > section.oss-about-what {
            float: right;
            position: relative;

            &::before {
                display: block;
                content: '';
                position: absolute;
                top: 0;
                left: -13%;
                bottom: 0;
                width: 20px;
                background-image: url("../../images/column-separator.png");
                background-position: left top;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }

        @media @media-halfwidth {
            & > section {
                width: auto;
            }

            & > section.oss-about-who {
                float: none;
                display: inline-block;
            }

            & > section.oss-about-what {
                float: none;
                display: inline-block;

                &::before {
                    display: none;
                    background: none;
                }
            }
        }
    }
}
