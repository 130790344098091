﻿@font-face {
    font-family: Ringo;
    font-style: normal;
    font-weight: 800;
    src: url('fonts/Ringo Black.otf') format('opentype');
}

@font-face {
    font-family: Ringo;
    font-style: normal;
    font-weight: 600;
    src: url('fonts/Ringo Bold.otf') format('opentype');
}

@font-face {
    font-family: Ringo;
    font-style: normal;
    font-weight: 300;
    src: url('fonts/Ringo Light.otf') format('opentype');
}

@font-face {
    font-family: Ringo;
    font-style: normal;
    font-weight: 400;
    src: url('fonts/Ringo Regular.otf') format('opentype');
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 800;
    src: url('fonts/Roboto-Black.ttf') format('truetype');
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 800;
    src: url('fonts/Roboto-BlackItalic.ttf') format('truetype');
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 600;
    src: url('fonts/Roboto-Bold.ttf') format('truetype');
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 600;
    src: url('fonts/Roboto-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    src: url('fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 300;
    src: url('fonts/Roboto-LightItalic.ttf') format('truetype');
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    src: url('fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 400;
    src: url('fonts/Roboto-Italic.ttf') format('truetype');
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    src: url('fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 500;
    src: url('fonts/Roboto-MediumItalic.ttf') format('truetype');
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 200;
    src: url('fonts/Roboto-Thin.ttf') format('truetype');
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 200;
    src: url('fonts/Roboto-ThinItalic.ttf') format('truetype');
}

@font-face {
    font-family: RobotoCondensed;
    font-style: normal;
    font-weight: 600;
    src: url('fonts/RobotoCondensed-Bold.ttf') format('truetype');
}

@font-face {
    font-family: RobotoCondensed;
    font-style: italic;
    font-weight: 600;
    src: url('fonts/RobotoCondensed-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: RobotoCondensed;
    font-style: normal;
    font-weight: 300;
    src: url('fonts/RobotoCondensed-Light.ttf') format('truetype');
}

@font-face {
    font-family: RobotoCondensed;
    font-style: italic;
    font-weight: 300;
    src: url('fonts/RobotoCondensed-LightItalic.ttf') format('truetype');
}

@font-face {
    font-family: RobotoCondensed;
    font-style: normal;
    font-weight: 400;
    src: url('fonts/RobotoCondensed-Regular.ttf') format('truetype');
}

@font-face {
    font-family: RobotoCondensed;
    font-style: italic;
    font-weight: 400;
    src: url('fonts/RobotoCondensed-Italic.ttf') format('truetype');
}
